export default {
    API: {
        Url: "https://api.raisingamathematician.com/api"
    },
    //Constants for the User Roles
    ROLE: {
        ADMIN: 1,
        STUDENT: 2
    },
    //Enrollment Status
    ENROL_STATUS: {
        DROPOUT: 3,
        ENROL: 2,
        APPROVE: 1,
        REJECT: 0
    },
    //Current Registration Status
    REGISTRATION_STATUS: [
        { status: "Approved" },
        { status: "Pending" },
        { status: "Rejected" },
        { status: "Enrolled" },
        { status: "Dropped" }
    ],
    //Professions
    PROFESSION: [{ profession: "Student" }, { profession: "Teacher" }, { profession: "Other Profession" }],
    //Registration Tags
    TAGS: [
        { id: 1, name: "LOW PRIORITY" },
        { id: 2, name: "MEDIUM PRIORITY" },
        { id: 3, name: "WAITING LIST" },
        { id: 4, name: "CAN BE SELECTED" },
        { id: 5, name: "SELECTED" },
        { id: 6, name: "INCOMPLETE APPLICATION" },
        { id: 7, name: "UNDER REVIEW" },
        { id: 8, name: "LOR IN EMAIL" },
        { id: 9, name: "MISSING DOCUMENTS" },
        { id: 10, name: "STUDENT CONTACTED" }
    ],
    //Mail Types
    MAIL_TYPES: [
        { id: 0, type: 'Confirm Application Payment' },
        { id: 1, type: 'Confirm Course Payment' },
        { id: 2, type: 'Confirm Donation Payment' },
        { id: 3, type: 'Confirm Register' },
        { id: 4, type: 'Approved' },
        { id: 5, type: 'Enrolled' },
        { id: 6, type: 'Rejected' },
        { id: 7, type: 'Bulk' },
        { id: 8, type: 'Dropped' }
    ],
    //Form Fields to be validated
    PersonalFields: ['firstName', 'lastName', 'emailId', 'mobile', 'profession','gender','dateOfBirth'],
    ResidenceFields: ['pincode', 'place', 'cityId', 'stateId', 'countryId', 'address'],
    AcademicFields: ['gradeId', 'institituteId', 'collegeDegreeId', 'boardId'],
    ParentFields: ['fatherName', 'motherName', 'fatherOccupation', 'motherOccupation','parentPhoneNo','parentEmailId'],
    CourseFields: ['programeInfoMode', 'scholarshipStatus', 'customeFields'],
    //Fee Types
    FEE_TYPES:["applicationFee","courseFee"],
    DEFAULT_PASSWORD:"Password1!",
COURSE_EPSILON_SCENARIO : 2
};
